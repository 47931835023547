import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import '@Hub/root.ngz.scss';
import '@Hub/root.scss';
import AppTheme from '@Hub/Theme';

import AppContext from '@core/context';
import usePageViewMetric from '@core/hooks/usePageViewMetric';
import { InitializeProjectStore, useSuperHubStore } from '@core/store';
import ScrollTop from '@core/utils/ScrollTop';

import HARContext from '@routes/Reference/context/HARContext';
import SuggestionsRouter from '@routes/Suggestions';
import { SuperHubRoutePaths } from '@routes/SuperHub/types';

import DeprecationWarning from '@ui/DeprecationWarning';
import { NotificationToaster } from '@ui/Notification';

/**
 * App Components
 */
const AppNav = loadable(() => import('./Header'), { ssr: true });
const AppFooter = loadable(() => import('./Footer'), { ssr: true });

/**
 * Route Components
 */
const Changelog = loadable(() => import('@routes/Changelog'), { ssr: true });
const CustomPage = loadable(() => import('@routes/CustomPage'), { ssr: true });
const DiscussionForum = loadable(() => import('@routes/Discuss'), { ssr: true });
const CopilotInstalledPage = loadable(() => import('@routes/Dash/ChatGPT/CopilotInstalledPage'), { ssr: true });
const DocsPage = loadable(() => import('@routes/Doc'), { ssr: true });
const OAuthRedirect = loadable(() => import('@routes/Reference/OAuthRedirect'), { ssr: true });
const Recipes = loadable(() => import('@routes/Tutorials'), { ssr: true });
const ReferencePage = loadable(() => import('@routes/Reference'), { ssr: true });
const GraphQLPage = loadable(() => import('@routes/GraphQL'), { ssr: false });
const PageNotFound = loadable(() => import('@routes/PageNotFound'), { ssr: true });
const LandingPage = loadable(() => import('@routes/Landing'), { ssr: true });
const SuperHub = loadable(() => import('@routes/SuperHub'), { ssr: true });
const SuperHubRoutes = loadable(() => import('@routes/SuperHub/Routes'), { ssr: true });

function AppRoutes({ is404, ...props }) {
  const [isSuperHub] = useSuperHubStore(s => [s.isSuperHub]);
  const { pathname } = useLocation();

  // Capture Page View Doc Metric on page navigation/load
  usePageViewMetric();

  return (
    <Switch>
      {
        // The 404 route has to be at the top of this switch
        // to account for optionally :slugged? route paths.
        !!is404 && (
          <Route path="*">
            <PageNotFound {...props} />
          </Route>
        )
      }
      <Route path="/copilot/installed">
        <CopilotInstalledPage {...props} />
      </Route>
      {!!isSuperHub && (
        <Switch>
          <Route exact path="/">
            <SuperHubRoutes {...props} />
          </Route>
          <Route path={Object.values(SuperHubRoutePaths)}>
            <SuperHubRoutes {...props} />
          </Route>
        </Switch>
      )}
      <Route path="/docs/:slug?">
        <ScrollTop deps={[pathname]} smooth />
        <DocsPage {...props} />
      </Route>
      <Route path="/reference/:slug?">
        <ScrollTop deps={[pathname]} smooth />
        <HARContext>
          <ReferencePage {...props} />
        </HARContext>
      </Route>
      <Route path="/oauth2-redirect">
        <ScrollTop deps={[pathname]} smooth />
        <OAuthRedirect {...props} />
      </Route>
      <Route path="/graphql">
        <ScrollTop deps={[pathname]} smooth />
        <GraphQLPage {...props} />
      </Route>
      <Route path="/changelog/:slug?">
        <Changelog {...props} />
      </Route>
      <Route path="/page/:slug?">
        <CustomPage {...props} />
      </Route>
      <Route path="/recipes">
        <Recipes {...props} />
      </Route>
      <Route path="/discuss*/:slug?">
        <DiscussionForum {...props} />
      </Route>
      {/* TODO: this route makes the suggested edits preview button work from angular.
              remove it once we're rendering suggested edits in React! */}
      <Route path="/suggested-edits/:slug/preview">
        <DocsPage {...props} suggestedEditsPreview />
      </Route>
      <Route path="/(edit|suggested-edits)(/*)?">
        <SuggestionsRouter {...props} />
      </Route>
      <Route exact path="/">
        <LandingPage {...props} />
      </Route>
      <Route>
        <PageNotFound {...props} />
      </Route>
    </Switch>
  );
}

AppRoutes.propTypes = {
  is404: PropTypes.bool,
};

const App = ({ children, config, context, glpString: enterpriseLP, is404, isDetachedProductionSite, ...props }) => {
  return (
    <InitializeProjectStore data={context.projectStore.data}>
      <AppContext
        apiBaseUrl={props.apiBaseUrl}
        baseUrl={props.baseUrl}
        config={config}
        context={context}
        enterpriseLP={enterpriseLP}
        isDetachedProductionSite={isDetachedProductionSite}
        language={props.lang}
        meta={props.meta}
        name="Hub"
        rdmd={props.rdmd}
      >
        <AppTheme>
          <SuperHub initialVersions={props.versions}>
            {children}

            <AppNav {...props} enterpriseLP={enterpriseLP} isDetachedProductionSite={isDetachedProductionSite} />

            {!!props.version?.is_deprecated && (
              <DeprecationWarning currentVersion={props.version.version} lang={props.lang} project={context.project} />
            )}
            <AppRoutes is404={is404} {...props} />
            <AppFooter isDetachedProductionSite={isDetachedProductionSite} />
            <div className="ModalWrapper" id="ChatGPT-modal" />
            {/* Top-level Toaster for displaying notifications in Hub */}
            <NotificationToaster />
          </SuperHub>
        </AppTheme>
      </AppContext>
    </InitializeProjectStore>
  );
};

App.propTypes = {
  apiBaseUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  // This should be our client-side safe config object, **NOT** the raw version!
  children: PropTypes.node,
  config: PropTypes.shape({
    asset_url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    domain: PropTypes.string,
    domainFull: PropTypes.string,
    legacy_asset_url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    metrics: PropTypes.shape({
      dashUrl: PropTypes.string,
      defaultUrl: PropTypes.string,
    }),
    releaseVersion: PropTypes.string,
    sentry: PropTypes.shape({
      dsn: PropTypes.string,
      enabled: PropTypes.bool,
      tracesSampleRate: PropTypes.number,
    }),
    sslGenerationService: PropTypes.string,
  }),
  context: PropTypes.shape({
    project: PropTypes.object,
    projectStore: PropTypes.object,
    terms: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    variables: PropTypes.object,
    version: PropTypes.object,
  }),
  glpString: PropTypes.string,
  is404: PropTypes.bool,
  isDetachedProductionSite: PropTypes.bool,
  isEnterpriseParent: PropTypes.bool,
  lang: PropTypes.string,
  meta: PropTypes.object,
  rdmd: PropTypes.object,
  sidebar: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  version: PropTypes.object,
  versions: PropTypes.object,
};

export default App;
